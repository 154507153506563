import React, {useEffect, useState} from "react"
import axios from 'axios';
import { useForm } from "react-hook-form";
import SearchResultsItem from "./SearchResultsItem";
const { GATSBY_API_URL = 'http://localhost' } = process.env;

export default () => {
    const { register, handleSubmit, reset, errors, setError } = useForm();
    const [input, setInput] = useState(null);
    const [ result, setResult ] = useState(null);
    const [ time, setTime ] = useState(null);
    const [ err, setErr ] = useState(null);
    const [inputClass, setInputClass] = useState(null);

    const isBrowser = () => typeof window !== "undefined";

    function getParameterByName(name) {
        if (!isBrowser()) {
            return '';
        }
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const keys = getParameterByName("keys");
    useEffect(() => {
        axios.get(`${GATSBY_API_URL}/api/search/results?keys=${keys}` )
            .then(res => {
                setInput(keys);

                if (keys === null || keys === "" ){
                    setInputClass("error");
                }
                if (res.data.length === 0 && keys !== null && keys !== ""){
                    setErr("Your search yielded no results.");
                }
                if (res.data.length > 0){
                    setTime(Date.now());
                    setResult(res.data);
                }

            })
    },[axios, setResult]);


    const onSubmit = data => {
        setResult(null);
        setErr(null);
        setInputClass(null);
        setTime(Date.now());

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";
        window.location.replace(`${baseUrl}search-page?keys=${input}`);
    };

    return (
        [<section className="container search-block">
            <div className="row">
                <div className="col-md-12 ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>Search</h1>
                        <label htmlFor="search">Enter your keywords </label>
                        <input className={inputClass} type="text" name="search" id="search" value={input} onInput={e => setInput(e.target.value)}/>
                        <button type="submit">Search</button>
                    </form>

                </div>
            </div>
        </section>,
            <section className="container search-results-block">
                <div className="row">
                    <div className="col-md-12 ">
                        {result &&
                        [<h2>Search results</h2>,
                            <p className="search-performance">The search found {result.length} results in {(Date.now()-time)/1000} seconds.</p>]
                        }

                        <ol className="search-results">
                            {err &&
                                <div>{err}</div>
                            }
                            {result &&
                            result.map((item, i)=> {
                                const href = window.location.origin.toString() +"/"+ item["#result"].link;
                                return (
                                    <SearchResultsItem title={item["#result"].title} link={href} body={item["#result"].snippet["#markup"]} />
                                    )})
                                }
                            </ol>
                        </div>
                    </div>
                </section>
            ]
    );
}
