import React from "react"


export default ({title, link, body}) => (
    <li>
        {title !== undefined &&
            <h3 className="title">
                <a href={link}>{title}</a>
            </h3>
        }
        {body !== undefined &&
        <div className="search-snippet-info" dangerouslySetInnerHTML={{
            __html: body
        }}>
        </div>
        }

    </li>
);
