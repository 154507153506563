import React from "react"

import Banner from "../components/Banner"
import OnboardSection from "../components/OnboardSection"
import OnboardSectionItems from "../components/OnboardSectionItems"
import VpgOnboard from "../components/VpgOnboard"
import EventNews from "../components/EventNews"
import FourBlock from "../components/FourBlock"
import Slider from "../components/Slider"
import Social from "../components/Social"
import Search from "../components/Search";
import SearchResults from "../components/SearchResults"
import Layout from "../components/Layout";
import SEO from "../components/SEO"
export default () => {
  //const { field_component = [] } = node;
  return (
      <Layout>
          <SEO title="search page" />
        <Banner />
        <Search />
        {/*<SearchResults />*/}

        {/*<FieldComponent data={field_component} />*/}
        {/*<OnboardSectionItems />*/}
        {/*<EventNews />*/}
        {/*<FourBlock />*/}
        {/*<Slider />*/}
        {/*<Social />*/}
    </Layout>
  );
};
